import React, { Component } from 'react';

import './index.scss';
import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import HowCanI from '../../components/how-can-i';
import ExternalLink from '../../components/external-link';
import Disclaimer from '../../components/disclaimer';
import BlingLowVisionLogo from '../../images/Blind_Low_Vision_NZ_Stacked_RGB.png';
import MDNZLogo from '../../images/MDNZ_logo.jpg';
import RetinaNZLogo from '../../images/Retina_NZ.jpg';
import ConsumerPanel from '../../components/consumer-panel';

class PatientPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('community-support');

    Array.from(document.getElementsByClassName('scrollToLink')).forEach(link => {
      link.addEventListener('click', e => {
        if (document.getElementById(link.getAttribute('scrollto'))) {
          e.preventDefault();
        }
        document.getElementById(link.getAttribute('scrollto')).scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    });
  }

  render() {
    const title = {
      english: 'Community Support'
    };
    return (
      <Layout title={title} showNavbar={true} className="patient">
        <section className="page-header support-page">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 col-md-5 p-5 d-flex align-items-center">
                <h1 className='support-heading'>
                  Community <br />
                  Support
                </h1>
              </div>
              <div className="col-12 col-md-7 p-5">
                <p>There are a number of groups that help support people who are living with wet AMD or DME. </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container my-5 px-5">
          <div className="row">
            <div className="col-12 col-md-7">
              <h2 className="h1 ornament pb-3">Blind Low Vision New Zealand</h2>
              <p>
                Blind Low Vision NZ delivers vision rehabilitation services.
                 Our mission is to empower people with vision impairment to live the life they choose.
              </p>
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <ExternalLink href="https://www.blindlowvision.org.nz">
                <img loading="lazy" alt="Blind Low Vision Logo" src={BlingLowVisionLogo} className={'img-fluid px-4'} />
              </ExternalLink>
            </div>
          </div>
        </section>

        <HowCanI titleEN="How can I contact Blind Low Vision New Zealand?">
          <p>
            <b>Website:</b> <ExternalLink href="https://www.blindlowvision.org.nz">www.blindlowvision.org.nz</ExternalLink>
          </p>
          <p>
            <b>Call Toll Free:</b> <a href="tel:0800 243 333" title="Contact Number">0800 243 333</a>
          </p>
        </HowCanI>

        <section className="container my-5 px-5" >
          <div className="row">
            <div className="col-12 col-md-7">
              <h2 className="h1 ornament pb-3">Macular Degeneration New Zealand</h2>
              <p>
                Macular Degeneration New Zealand’s mission is to reduce the incidence and impact of macular
                 degeneration through increasing awareness, promoting early detection, providing education,
                 information and support to those with macular degeneration and their carers/family.
              </p>
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <ExternalLink href="https://www.mdnz.org.nz">
                <img loading="lazy" src={MDNZLogo} alt="Macular Degeneration New Zealand’s Logo" className={'img-fluid'} />
              </ExternalLink>
            </div>
          </div>
        </section>

        <HowCanI titleEN="How can I contact Macular Degeneration New Zealand?">
          <p>
            <b>Website:</b> <ExternalLink href="https://www.mdnz.org.nz">www.mdnz.org.nz</ExternalLink>
          </p>
          <p>
            <b>Call Toll Free:</b> <a href="tel:0800 622 852" title="Call us on">0800 622 852</a>
          </p>
          <p>
            <b>Email:</b>{' '}
            <a target="_blank" rel="noreferrer" href="mailto:info@mdnz.org.nz" title="mail Us on">
            info@mdnz.org.nz
            </a>
          </p>
        </HowCanI>

        <section className="container my-5 px-5" >
          <div className="row">
            <div className="col-12 col-md-7">
                <h2 className="h1 ornament pb-3">Retina New Zealand</h2>
                <p>
                  Retina New Zealand's mission is to support people in New Zealand experiencing sight loss,
                   and their families and friends, by providing easy access to the information and support they might
                   need at different stages of their sight loss journey.
                </p>
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <ExternalLink href="https://www.retina.org.nz">
                <img loading="lazy" src={RetinaNZLogo} alt="Retina NZ Logo" className={'img-fluid px-4'} />
              </ExternalLink>
            </div>
          </div>
        </section>

        <HowCanI titleEN="How can I contact Retina New Zealand?">
          <p>
            <b>Website:</b> <ExternalLink href="https://www.retina.org.nz">www.retina.org.nz</ExternalLink>
          </p>
          <p>
            <b>Call Toll Free:</b> <a href="tel:0800 569 849" title="Phone Number">0800 569 849</a>
          </p>
          <p>
            <b>Email:</b>{' '}
            <a target="_blank" title="mail us on mailto:admin@retina.org.nz" rel="noreferrer" href="mailto:admin@retina.org.nz">
            admin@retina.org.nz
            </a>
          </p>
        </HowCanI>

        <p className={'text-center mt-4 disclaimer'}>* Websites owned and operated by independent third parties.</p>
        <div className={'d-flex justify-content-center align-items-center mx-auto p-5 mt-5 quote'}>
          <p className={'text-center mt-4'}>Ask your doctor if VABYSMO is right for you.</p>
        </div>

        <Disclaimer />
        <ConsumerPanel />
      </Layout>
    );
  }
}

export default PatientPage;
